import { useState } from 'react'
import { Row, Col, Container, Form, Image, Card, Alert } from 'react-bootstrap'
import "./Contact.scss"
import Agency from '#components/agency/Agency'
import { Bounce } from 'react-awesome-reveal'
import { useSendMail } from '#hooks'
import ScrollToTop from '#helpers/ScrollToTop'

const validateTimeOut = {
  email: null,
  message: null,
  name: null,
  phone: null
}

const isValid = input => {
  input.value = input.value.trim()
  if (input.name === 'phone') {
    input.value = validatePhoneNumber(input.value)
  }
  return input.reportValidity()
}

const validatePhoneNumber = number => number.replaceAll(/[^0-9]/g, '')

const Contact = () => {
  const mailer = useSendMail()
  /* Possible values for formState is idle, sending, error, success */
  const [formState, setFormState] = useState('idle')

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (formState !== 'sending') { // To prevent double submission
      const form = ev.target

      if (!isValid(form.name)) form.name.classList.add('input-error')
      if (!isValid(form.email)) form.email.classList.add('input-error')
      if (!isValid(form.message)) form.message.classList.add('input-error')
      if (!isValid(form.phone)) form.phone.classList.add('input-error')

      if (form.reportValidity()) {
        setFormState('sending') // This will disable all input fields
        const mailStatus = await mailer.send(form.name.value, form.email.value, form.phone.value, form.message.value)

        if (mailStatus.ok) {
          form.reset()
          setFormState('success')
        } else {
          setFormState('error')
        }
      }
    }
  }

  const validateInput = ev => {
    const input = ev.target
    const validate = input => {
      if (formState !== 'idle') setFormState('idle')
      if (!isValid(input)) {
        input.classList.add('input-error')
      } else {
        input.classList.remove('input-error')
      }
    }

    if (validateTimeOut[`${input.name}`]) {
      clearTimeout(validateTimeOut[`${input.name}`])
    }

    validateTimeOut[`${input.name}`] = setTimeout(() => validate(ev.target), 800)
  }

  return (
    <>
      <ScrollToTop />
      <Container className='sect contact-sect' fluid>
        <Bounce triggerOnce delay={200} fraction={0.2}>
          <Card className='shadow'>
            <Card.Body>
              <Container>
                <Row>
                  <Col md={4}>
                    <div className='d-md-none contact-text'>
                      <div className='text-center'>
                        <h1 className='heading text-primary'>Contact Us</h1>
                      </div>
                    </div>
                    <div className='contact-img'>
                      <Image src='/assets/img/contact.png' alt='contact us at Kolat OAK Logistics'/>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='d-none d-md-block contact-text'>
                      <div>
                        <h1 className='heading text-primary'>Contact Us</h1>
                      </div>
                    </div>
                    <div className='contact-form'>
                      <Form onSubmit={handleSubmit}>
                        <h4 className='mt-3 text-center d-md-none'>Fill this form to reach us</h4>
                        <Alert variant='success' style={{display: formState !== 'success' && 'none'}}>Message sent succesfully</Alert>
                        <Alert variant='danger' style={{display: formState !== 'error' && 'none'}}>Oops! An error occured, please try again</Alert>
                        <Form.Group className='mb-3'>
                          <Form.Control 
                          type='text' 
                          onChange={validateInput} 
                          name='name' 
                          placeholder='Name' 
                          required 
                          aria-required={true}
                          disabled={formState === 'sending'}/>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <Form.Control 
                          type='email' 
                          onChange={validateInput} 
                          name='email' 
                          placeholder='Email' 
                          required 
                          aria-required={true}
                          disabled={formState === 'sending'}/>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <Form.Control 
                          type='tel' 
                          onChange={validateInput} 
                          name='phone' 
                          placeholder='Phone Number' 
                          required 
                          aria-required={true}
                          disabled={formState === 'sending'}/>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                          <textarea 
                          type='textarea' 
                          onChange={validateInput} 
                          className='form-control' 
                          minLength={80} 
                          name='message' 
                          placeholder='What will you like to say to us?' 
                          required 
                          aria-required={true}
                          disabled={formState === 'sending'}>
                          </textarea>
                        </Form.Group>
                        <div className='d-flex justify-content-end'>
                          <input type='submit' value={formState === 'sending' ? 'Submitting' : 'Submit'} className='btn btn-primary submit-btn'/>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Bounce>
      </Container>
      <Agency />
    </>
  )
}

export default Contact
import React from 'react'
import { Row, Col, Image, Container } from 'react-bootstrap'
import ServicesCard from '#components/servicesCard/ServicesCard'
import ServicesCarousel from '#components/servicesCarousel/ServicesCarousel'
import './Home.scss'
import Mission from '#components/mission/Mission'
import CoreValues from '#components/coreValues/CoreValues'
import Agency from '#components/agency/Agency'
import { Link } from 'react-router-dom'
import ContactUs from '#components/contactUs/ContactUs'
import { Fade, Bounce } from 'react-awesome-reveal'
import ScrollToTop from '#helpers/ScrollToTop'
import navMapping from '#config/routes'

export const Home = () => {
  
  return (
    <>
      <ScrollToTop />
      <Container fluid className='p-0'>
        <ServicesCarousel />
      </Container>
      <Container className='d-flex justify-content-center' fluid>
        <Row className='about-sect-home'>
          <Col xs={12} className='d-md-none'>
            <h2 className='heading text-primary'>About Kolat-OAK Logistics</h2>
          </Col>
          <Col xs={12} md={6} className='d-flex justify-content-center align-items-center m-0'>
            <Fade triggerOnce>
              <div className='about-img-div'>
                <Image src='/assets/img/about.jpg' alt='about Kolat Logistics'/>
              </div>
            </Fade>
          </Col>
          <Col xs={12} md={6} className='about-text-div m-0'>
            <Bounce triggerOnce fraction={0.2}>
              <div>
                <h2 className='d-none d-md-block text-primary'>About Kolat-OAK Logistics</h2>
                <p>
                  KOLAT-OAK LOGISTICS AGENCY is a leading Freight Forwarding Company and International Logistics 
                  Service Provider that specializes in cargo business. A company that is reliable,
                  talented with qualified, competent and knowledgeable staff that understand harmonized tariff very 
                  well with years of experience.
                </p>
                <p>
                  Our vast knowledge in the maritime world has helped our company develop Overseas Forwarders 
                  and Agents, Shipper, Sellers, Coloaders, Suppliers for all their arriving consignments.
                </p>
                <Link to={navMapping.about.pathName} className='btn btn-outline-primary'>Learn More</Link>
              </div>
            </Bounce>
          </Col>
        </Row>
      </Container>
      <ServicesCard style={{backgroundColor: 'var(--bs-gray-200)'}} />
      <Container fluid>
        <CoreValues />
      </Container>
      <Mission />
      <Agency />
      <ContactUs />
    </>
  )
}

import React from 'react'
import { Row, Col, Image, Container } from 'react-bootstrap'
import Mission from '#components/mission/Mission'
import CoreValues from '#components/coreValues/CoreValues'
import Agency from '#components/agency/Agency'
import './About.scss'
import { Fade, Bounce } from 'react-awesome-reveal'
import ScrollToTop from '#helpers/ScrollToTop'

const About = () => {
  return (
    <>
      <ScrollToTop />
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className='main-img' style={{backgroundImage: 'url(/assets/img/Air-slider.webp)'}}>
              {/* <Image src='' alt='Kolat Logistics services' /> */}
            </div>
          </Col>
        </Row>
        <Row className='sect about-sect' style={{paddingTop: '3rem'}}>
          <Col xs={12} className='d-md-none'>
            <h2 className='heading text-primary'>About Kolat-OAK Logistics</h2>
          </Col>
          <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
            <div className='about-img-div'>
              <Image src='/assets/img/about.jpg' className='img-fluid' alt='about us at kolat logistics'/>
            </div>
          </Col>
          <Col xs={12} md={6} className='d-flex align-items-center'>
            <Bounce triggerOnce fraction={0.2}>
              <div className='about-text'>
                <h2 className='d-none d-md-block text-primary heading'>About Kolat-OAK Logistics</h2>
                <p>
                <strong>KOLAT-OAK LOGISTICS AGENCY</strong> is a leading Freight Forwarding Company and International Logistics 
                  Service Provider that specializes in cargo business. A company that is reliable,
                  talented with qualified, competent and knowledgeable staff that understand harmonized tariff very 
                  well with years of experience.
                </p>
                <p>
                  Our vast knowledge in the maritime world has helped our company develop Overseas Forwarders 
                  and Agents, Shipper, Sellers, Coloaders, Suppliers for all their arriving consignments.
                </p>
              </div>
            </Bounce>
          </Col>
          <Col className='d-flex justify-content-center'>
            <div className='mw-md-50 extra-about-div'>
              <Fade triggerOnce cascade fraction={0.2} delay={200}>
                  <p>
                    We are very active, reliable, and experienced Cargo coordinators with Specialist in Clearing 
                    of Consignment by Airlines and Shipping Consolidation for all arriving consignments either by 
                    Air freight or Sea freight and We were able to distinguish ourselves through our honest approach 
                    to our customers as well as efficiency with which we handle our consignments be at Airport or Seaport.
                  </p>
                  <p>
                    <strong>KOLAT-OAK LOGISTICS AGENCY</strong> is always engaging in new strategies to 
                    cope with our rapidly changing business environment.
                  </p>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      <Mission />
      <div className='border-4 border-bottom border-primary w-100'></div>
      <CoreValues />
      <div className='border-4 border-bottom border-primary w-100'></div>
      <Agency />
    </>
  )
}

export default About
import './SideBar.scss'
import { Image } from 'react-bootstrap'
import { Fade } from 'react-awesome-reveal'

const SideBar = () => {
  return (
    <div className='shadow side-bar'>
        <Fade delay={300}>
            <a href='https://wa.me/+2348023957077' rel='noreferrer' target='_blank'>
                <Image src='/assets/svg/whatsapp.svg' alt='connect with Kolat on whatsapp' />
            </a>
        </Fade>
    </div>
  )
}

export default SideBar
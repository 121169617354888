import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import IncoTerms from '#components/incoTerms/IncoTerms'
import FreightPallet from '#components/freightPallet/FreightPallet'
import ContainerSpecification from '#components/containerSpecification/ContainerSpecification'
import Agency from '#components/agency/Agency'
import ScrollToTop from '#helpers/ScrollToTop'

const resourcesMap = {
  'inco-terms': <IncoTerms />,
  'freight-pallets': <FreightPallet />,
  'container-specification': <ContainerSpecification />
}

const Resources = () => {

  const { resource } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Object.keys(resourcesMap).includes(resource)) navigate('/404')
  })

  return (
    <>
      <ScrollToTop />
      <Container className='' fluid>
        <Row className='sect px-sm-3 px-lg-5' style={{backgroundColor: 'var(--bs-gray-200)'}}>
          { resourcesMap[resource] }
        </Row>
      </Container>
      <Agency />
    </>
  )
}

export default Resources
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useServices } from '#hooks'
import './ServiceCard.scss'
import { Fade } from 'react-awesome-reveal'
import { Link } from 'react-router-dom'
import { parseAndCutSentence } from '#helpers'
import navMapping from '#config/routes'

const ServicesCard = (props) => {
  const services = useServices()
  return (
    <Container className='sect service-sect' style={props.style} fluid>
      <Row className='justify-content-center g-4'>
        <Col xs={12}>
          <h2 className='heading text-primary main-heading'>What we offer</h2>
        </Col>
        {
          services.map((service, index) => 
            <Col xs={12} sm={6} lg={4} xl={3} key={index}>
              <Fade fraction={0.2} cascade triggerOnce>
                <div className='service-card'>
                  <div className='service-img' style={{backgroundImage: `url(${service.imgURL})`}}></div>
                  <div className='service-text'>
                    <Card>
                      <Card.Body>
                        <h5 className='heading'>{service.title}</h5>
                        <p>{parseAndCutSentence(service.description)}</p>
                        <Link to={`${navMapping.services.pathName}?title=${service.title}`}>Read More</Link>
                      </Card.Body>
                    </Card>
                  </div>
                  </div>
              </Fade>
            </Col>
          )
        }
      </Row>
      {/* <Row className='justify-content-center g-1'>
        <Col xs={12}>
          <h2 className='heading main-heading'>What we offer</h2>
        </Col>
        {
          services.map((service, index) => 
            <Col xs={12} sm={6} lg={4} key={index}>
              <div className='service-card' style={{backgroundImage: `url(${service.imgURL})`}}>
                <p>{service.title}</p>
              </div>
            </Col>
          )
        }
      </Row> */}
    </Container>
  )
}

export default ServicesCard
import React from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Footer.scss'

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-light footer wow fadeIn footer px-0" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row gy-5 sects">
          <div className="col sect-1">
            {/* <h4 className="text-light mb-4">Address</h4> */}
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>6 MMIA Road, Lagos, Nigeria.</p>
            <p className="mb-2">
              <a href='tel:+234 703 7476 444' className='text-white'>
                <Image src='/assets/svg/headphone.svg' className="me-3 headphone"/>+234 703 7476 444
              </a>
            </p>
            <p className="mb-2">
              <a href='mailto:info@kolatlogistics.com?subject=Hello Kolat' className='text-white'>
                info@kolatlogistics.com
              </a>
            </p>
            <div className="social-div mb-3">
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/TheKolat">
                <Image src='/assets/svg/twitter.svg' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/kolatunde.akanni">
                <Image src='/assets/svg/facebook.svg' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/thekolat/">
                <Image src='/assets/svg/instagram.png' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/kolat-cargo-158b4955">
                <Image src='/assets/svg/linkedin.svg' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+2348023957077">
                <Image src='/assets/svg/whatsapp.svg' />
              </a>
            </div>
            <div>
              &copy; <Link className="text-white" to="/">Kolat-OAK Logistics Agency</Link>, All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
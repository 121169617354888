import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import './CoreValues.scss'
import { Fade } from 'react-awesome-reveal'

const CoreValues = () => {
  return (
    <Fade triggerOnce delay={200}>
      <Container id='values' className='core-value-div' fluid>
        <Row>
          <Col xs={12} md={6} className='d-flex align-items-center values-col'>
            <div>
            <h2 className='heading text-primary'>Our Core Values</h2>
            <div>
              <ul>
                <li>
                  Continuously guided by our team work with commitment, efficiency, perseverance, accountability,
                  integrity and dedication.
                </li>
                <li>Reward and performance</li>
                <li>Innovation</li>
                <li>Profitability</li>
                <li>Learning continuously</li>
                <li>Excellence</li>
              </ul>
            </div>
            </div>
          </Col>
          <Col xs={12} md={6} className='d-sm-none d-md-block'>
            <div className='core-values-img-div'>
              <img src='/assets/img/values.jpg' alt='Kolat Logistics core values'/>
            </div>
          </Col>
        </Row>
      </Container>
    </Fade>
  )
}

export default CoreValues
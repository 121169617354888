import React, { useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

const scrollToTop = () => {
    window.scrollTo(0,0)
}

const ScrollToTop = () => {
  const navigationType = useNavigationType()
  const location = useLocation()
  useEffect(() => {
    if (navigationType === 'PUSH' || navigationType === 'REPLACE') scrollToTop()
  }, [location, navigationType])
  return (
    <div className='d-none'></div>
  )
}

export default ScrollToTop
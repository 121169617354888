import React from 'react'
import { useAgency } from '#hooks'
import './Agency.scss'
import { Container } from 'react-bootstrap'
import { Fade } from 'react-awesome-reveal'

const Agency = (props) => {
  const {style} = props
  const agencies = useAgency()
  return (
    <Container className='sect agency-div' style={style} fluid>
        <h2 className='heading text-primary'>Our Partners</h2>
          <p>We have become a leading Int’l logistics company in Nigeria and a good member of famous worldwide companies such as: 
            {' '}<strong>FIATA</strong>,{' '}<strong>IATA</strong>,{' '}<strong>WCA</strong>,{' '}<strong>JC Trans</strong>,{' '}
            <strong>CargoYellow</strong>,{' '}<strong>FreightNet</strong>
          </p>
          <Fade cascade triggerOnce fraction={0.2}>
            <div className='d-flex logo-div'>
              {
                agencies.map((agency, index) => 
                  <div 
                    className={`${agency.name.toLowerCase()}`} 
                    style={{backgroundImage: `url(${agency.imgURL})`, ...agency.style}} 
                    key={index}>
                  </div>
                )
              }
            </div>
          </Fade>
      </Container>
  )
}

export default Agency
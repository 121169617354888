import React from 'react'
import { Col, Table } from 'react-bootstrap'
import './IncoTerms.scss'

const IncoTerms = () => {
  return (
    <Col className='inco-terms'>
        <h2 className='heading heading-centered heading-underlined text-primary'>INCO Terms</h2>
        <Table striped responsive>
            <thead className='bg-primary text-light'>
                <tr>
                    <th scope="col">Inco term 2020</th>
                    <th scope="col">Loading on truck (carrier)</th>
                    <th scope="col">Export Customs declaration</th>
                    <th scope="col">Carriage to port of export</th>
                    <th scope="col">Unloading of truck in port of export</th>
                    <th scope="col">Loading charges in port of export</th>
                    <th scope="col">Carriage to port of import</th>
                    <th scope="col">Unloading charges in port of import</th>
                    <th scope="col">Loading on truck in port of import</th>
                    <th scope="col">Carriage to place of destination</th>
                    <th scope="col">Insurance</th>
                    <th scope="col">Import Customs Clearance</th>
                    <th scope="col">Import Taxes</th>
                </tr>
            </thead>
            <tbody>
                <tr className=" ">
                    <th scope="row">EXW</th>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">FCA</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">FAS</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">FOB</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">CFR</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">CIF</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">DAT</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">CPT</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">DAP</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">CIP</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="buyer">Buyer</td>
                    <td className="buyer">Buyer</td>
                </tr>
                <tr className="">
                    <th scope="row">DDP</th>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                    <td className="seller">Seller</td>
                </tr>
            </tbody>
        </Table>
    </Col>
  )
}

export default IncoTerms
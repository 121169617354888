import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './ContactUs.scss'
import { Slide } from 'react-awesome-reveal'
import navMapping from '#config/routes'

const ContactUs = () => {
  return (
    <Container className='sect contact-sect-home' style={{backgroundColor: 'var(--bs-gray-200)'}} fluid>
        
        <Row>
            <Col xs={12} className='d-md-none'>
                <h2 className='heading text-primary'>Contact us</h2>
            </Col>
            <Col xs={12} md={4} lg={5} className='image-col'>
                <Slide triggerOnce fraction={0.2} direction='left'>
                    <div>
                        <Image src='/assets/img/contact.png' className='img-fluid' alt='contact kolat oak logistics agency' />
                    </div>
                </Slide>
            </Col>
            <Col xs={12} md={8} lg={7} className='text-col'>
                <Slide triggerOnce delay={200} direction='right'>
                    <div className='shadow contact-text text-white'>
                        <h2 className='d-none d-md-block heading'>Contact us</h2>
                        <p>
                            Information is key to making decisions on the go. Knowing this, we have dedicated a team of 
                            experts to specially tend to your needs. Feel free to reach out to us should you need any intel 
                            on our services or your cargo status. We will be very happy to help you out.
                        </p>
                        <Link className='btn btn-outline-light' to={navMapping.contact.pathName}>Contact Us</Link>
                    </div>
                </Slide>
            </Col>
        </Row>
    </Container>
  )
}

export default ContactUs
export const cutSentence = (sentence, max_num) => {
    /**
     * If the characters in sentence is greater than MAX_NUM, cuts the sentence 
     * at the next index of space character and appends ellipses to it
     * else it returns the full sentence
     * 
     * @param sentence (string) - the sentence to be processed
     * @param max_num (number) - the maximum number of characters allowed in the sentence
     * 
     * Return: the processed string
     */
    const MAX_NUM = max_num || 250
    const delimiter = ' '
    if (sentence.length > MAX_NUM) {
      const positionOfNextDelimiter = sentence.indexOf(delimiter, MAX_NUM)
      const processedWord = sentence.slice(0, positionOfNextDelimiter) + '...'
      return processedWord
    }
    return sentence
}

export const removeHTMLTags = (sentence)  => {
  /**
   * Removes html tags from a sentence
   * 
   * @param sentence - the sentence to be stripped off html tags
   * Return: the stripped sentence (string)
   */
  let sentenceCopy;
  /* Remove anything between <> */
  sentenceCopy = sentence.replaceAll(/<[^>]*>/ig, ' ')
  /* Remove extra spaces */
  sentenceCopy = sentenceCopy.replaceAll(/\s+/g, ' ').trim()
  return sentenceCopy
}

export const parseAndCutSentence = (sentence, MAX_NUM) => {
    /**
     * Removes HTML Tags from a sentence
     * If the characters in sentence is greater than MAX_NUM, cuts the sentence 
     * at the next index of space character and appends ellipses to it
     * else it returns the full sentence
     * 
     * @param sentence (string) - the sentence to be processed
     * @param max_num (number) - the maximum number of characters allowed in the sentence
     * 
     * Return: the processed string
     */

    let parsedString = removeHTMLTags(sentence)
    parsedString = cutSentence(parsedString, MAX_NUM)

    return parsedString
}

export const setInnerHTML = (markup) => {
    return {__html: markup}
}
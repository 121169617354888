import React from 'react'
import { Carousel } from 'react-bootstrap'
import { useServices } from '#hooks'
import './ServiceCarousel.scss'
import Typewriter from 'typewriter-effect'
import { Fade } from 'react-awesome-reveal'

const ServicesCarousel = () => {
  const services = useServices()
  const animatedText = 
    <Typewriter
      onInit={(typewriter) => {
        typewriter
        .typeString("<span class='main-intro'>Kolat-OAK Logistics Agency<span>")
        .pauseFor(1000)
        .typeString(`<span class="extra-intro">A leading freight forwarding company and international
        ${' '}logistics service provider.</span>`)
        .start()
      }}
      skipAddStyles={true}
      loop={true}
      cursor=''
    />
  return (
    <div className='header-carousel' >
      <div className='welcome-div'>
        <Fade triggerOnce delay={200} cascade>
          <h4>Welcome to,</h4>
          <div>{ animatedText }</div>
        </Fade>
      </div>
      <Carousel controls={false} indicators={false} fade>
      {
        services.map((service, index) => 
          <Carousel.Item key={index}>
            <div className='carousel-content' style={{backgroundImage: `url(${service.imgURL})`}}>
              <div className='content-header'>
                <Fade delay={1000}>
                  <p>{service.title}</p>
                </Fade>
              </div>
            </div>
          </Carousel.Item>
        )
      }
    </Carousel>
    </div>
  )
}

export default ServicesCarousel
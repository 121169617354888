import React from 'react'
import { Col, Table } from 'react-bootstrap'
import './ContainerSpecification.scss'

const ContainerSpecification = () => {
  return (
    <Col className="container-specification">
      <h2 className='heading heading-centered heading-underlined text-primary'>Container Specification</h2>
    <Table className="table-bordered" responsive>
        <tbody>
            <tr>
                <th rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/standard-20-1.jpg" alt="" align="top" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/standard-20-1.jpg" alt="" align="top" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/standard-20-1.jpg" alt="" align="top" />
                            </noscript>
                        </noscript>
                        Standard 20&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>19&#8217;4&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;10&#8221;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;6&#8243;</td>
                <td>1,172 Cft</td>
                <td>4,916 Lbs</td>
                <td>47,900 Lbs</td>
            </tr>
            <tr>
                <td>5.900 m</td>
                <td>2.350 m</td>
                <td>2.393 m</td>
                <td>2.342 m</td>
                <td>2.280 m</td>
                <td>33.2 Cu.m</td>
                <td>2,230 Kgs</td>
                <td>21,770 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3" width="17%">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/standard-40-2.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/standard-40-2.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/standard-40-2.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Standard 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>39&#8217;5&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;10&#8221;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;6&#8243;</td>
                <td>2,390 Cft</td>
                <td>8,160 Lbs</td>
                <td>59,040 Lbs</td>
            </tr>
            <tr>
                <td>12.036 m</td>
                <td>2.350 m</td>
                <td>2.392 m</td>
                <td>2.340 m</td>
                <td>2.280 m</td>
                <td>67.7 Cu.m</td>
                <td>3,700 Kgs</td>
                <td>26,780 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3" width="18%">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/high-cube-20-3.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/high-cube-20-3.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/high-cube-20-3.jpg" alt="" />
                            </noscript>
                        </noscript>
                        High Cube 20&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>19&#8217;4&#8243;</td>
                <td>7&#8217;7&#8243;</td>
                <td>7&#8217;10&#8221;</td>
                <td>7&#8217;6&#8243;</td>
                <td>7&#8217;6&#8243;</td>
                <td>1,150 Cft</td>
                <td>5,060 Lbs</td>
                <td>61,996 Lbs</td>
            </tr>
            <tr>
                <td>5. 892 m</td>
                <td>2.311 m</td>
                <td>2.390 m</td>
                <td>2.286 m</td>
                <td>2.280 m</td>
                <td>32.63 Cu.m</td>
                <td>2,300 Kgs</td>
                <td>28,180 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3" width="18%">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/high-cube-40.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/high-cube-40.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/high-cube-40.jpg" alt="" />
                            </noscript>
                        </noscript>
                        High Cube 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>39&#8217;5&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>8&#8217;10&#8221;</td>
                <td>7&#8217;8&#8243;</td>
                <td>8&#8217;5&#8243;</td>
                <td>2,694 Cft</td>
                <td>8,750 Lbs</td>
                <td>58,450 Lbs</td>
            </tr>
            <tr>
                <td>12.036 m</td>
                <td>2.350 m</td>
                <td>2.697 m</td>
                <td>2.338 m</td>
                <td>2.258 m</td>
                <td>76.3 Cu.m</td>
                <td>3,970 Kgs</td>
                <td>26,510 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3" width="19%">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Reefer-20-5.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Reefer-20-5.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Reefer-20-5.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Reefer 20&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>17&#8217;8&#8243;</td>
                <td>7&#8217;5&#8243;</td>
                <td>7&#8217;5&#8243;</td>
                <td>7&#8217;5&#8243;</td>
                <td>7&#8217;3&#8243;</td>
                <td>1,000 Cft</td>
                <td>7,040 Lbs</td>
                <td>45,760 Lbs</td>
            </tr>
            <tr>
                <td>5.425 m</td>
                <td>2.275 m</td>
                <td>2.260 m</td>
                <td>2.258 m</td>
                <td>2.216 m</td>
                <td>28.3 Cu.m</td>
                <td>3,200 Kgs</td>
                <td>20,800 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3" width="19%">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Reefer-40-6.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Reefer-40-6.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Reefer-40-6.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Reefer 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>37&#8217;8&#8243;</td>
                <td>7&#8217;5&#8243;</td>
                <td>7&#8217;2&#8243;</td>
                <td>7&#8217;5&#8243;</td>
                <td>7&#8217;10&#8221;</td>
                <td>2,040 Cft</td>
                <td>10,780 Lbs</td>
                <td>56,276 Lbs</td>
            </tr>
            <tr>
                <td>11.493 m</td>
                <td>2.270 m</td>
                <td>2.197 m</td>
                <td>2.282 m</td>
                <td>2.155 m</td>
                <td>57.8 Cu.m</td>
                <td>4,900 Kgs</td>
                <td>25,580 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div><img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Reefer-40-High-Cube-7.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Reefer-40-High-Cube-7.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Reefer-40-High-Cube-7.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Reefer 40&#8242; High Cube
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>37&#8217;11&#8221;</td>
                <td>7&#8217;6&#8243;</td>
                <td>8&#8217;2&#8243;</td>
                <td>7&#8217;6&#8243;</td>
                <td>8&#8217;0&#8243;</td>
                <td>2,344 Cft</td>
                <td>9,900 Lbs</td>
                <td>57,561 Lbs</td>
            </tr>
            <tr>
                <td>11.557 m</td>
                <td>2.294 m</td>
                <td>2.500 m</td>
                <td>2.294 m</td>
                <td>2.440 m</td>
                <td>66.6 Cu.m</td>
                <td>4,500 Kgs</td>
                <td>25,580 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3"><div>
                    <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/open-top-20-8.jpg" alt="" />
                    <noscript>
                        <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/open-top-20-8.jpg" alt="" />
                        <noscript><img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/open-top-20-8.jpg" alt="" />
                        </noscript>
                    </noscript>
                    Open Top 20&#8242;
                </div>
            </th>
            <th>Inside Length</th>
            <th>Inside Width</th>
            <th>Inside Height</th>
            <th className="td-heading" width="9%">Door Width</th>
            <th className="td-heading" width="10%">Door Height</th>
            <th className="td-heading" width="8%">Capacity</th>
            <th className="td-heading" width="10%">Tare Weight</th>
            <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>19&#8217;4&#8243;</td>
                <td>7&#8217;7&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;6&#8243;</td>
                <td>7&#8217;2&#8243;</td>
                <td>1,136 Cft</td>
                <td>5,280 Lbs</td>
                <td>47,620 Lbs</td>
            </tr>
            <tr>
                <td>5.894 m</td>
                <td>2.311 m</td>
                <td>2.354 m</td>
                <td>2.286 m</td>
                <td>2.184 m</td>
                <td>32.23 Cu.m</td>
                <td>2,400 Kgs</td>
                <td>21,600 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/open-top-40-9.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/open-top-40-9.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/open-top-40-9.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Open Top 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>39&#8217;5&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;8&#8243;</td>
                <td>7&#8217;5&#8243;</td>
                <td>2,350Cft</td>
                <td>8,490 Lbs</td>
                <td>58,710 Lbs</td>
            </tr>
            <tr>
                <td>12.028 m</td>
                <td>2.350 m</td>
                <td>2.345 m</td>
                <td>2.341 m</td>
                <td>2.274 m</td>
                <td>65.5 Cu.m</td>
                <td>3,850 Kgs</td>
                <td>26,630 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/flat-rack-20-10.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/flat-rack-20-10.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/flat-rack-20-10.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Flat Rack 20&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>18&#8217;5&#8243;</td>
                <td>7&#8217;3&#8243;</td>
                <td>7&#8217;4&#8243;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>5,578 Lbs</td>
                <td>47,333 Lbs</td>
            </tr>
            <tr>
                <td>5.620 m</td>
                <td>2.200 m</td>
                <td>2.233 m</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>5,578 Kgs</td>
                <td>21,470 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Flat-Rack-40-11.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Flat-Rack-40-11.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Flat-Rack-40-11.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Flat Rack 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>39&#8217;7&#8243;</td>
                <td>6&#8217;10&#8221;</td>
                <td>6&#8217;5&#8243;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>12,081 Lbs</td>
                <td>85,800 Lbs</td>
            </tr>
            <tr>
                <td>12.080 m</td>
                <td>2.438 m</td>
                <td>2.103 m</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>5,480 Kgs</td>
                <td>39,000 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div><img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Flat-Rack-Collapsible-20-12.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Flat-Rack-Collapsible-20-12.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Flat-Rack-Collapsible-20-12.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Flat Rack Collapsible 20&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>18&#8217;6&#8243;</td>
                <td>7&#8217;3&#8243;</td>
                <td>7&#8217;4&#8243;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>6,601 Lbs</td>
                <td>61,117 Lbs</td>
            </tr>
            <tr>
                <td>5.618 m</td>
                <td>2.208 m</td>
                <td>2.233 m</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>2,750 Kgs</td>
                <td>27,730 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Flat-Rack-Collapsible-40-Stake-Bed-13.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Flat-Rack-Collapsible-40-Stake-Bed-13.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Flat-Rack-Collapsible-40-Stake-Bed-13.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Flat Rack Collapsible 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>39&#8217;7&#8243;</td>
                <td>6&#8217;10&#8221;</td>
                <td>6&#8217;5&#8243;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>12,787 Lbs</td>
                <td>85,800 Lbs</td>
            </tr>
            <tr>
                <td>12.080 m</td>
                <td>2.126 m</td>
                <td>2.043 m</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>5,800 Kgs</td>
                <td>39,000 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/platform-20-14.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/platform-20-14.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/platform-20-14.jpg" alt="" />
                            </noscript>
                        </noscript> 
                        Platform 20&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>19&#8217;11&#8221;</td>
                <td>8&#8217;0&#8243;</td>
                <td>7&#8217;4&#8243;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>6,061 Lbs</td>
                <td>52,896 Lbs</td>
            </tr>
            <tr>
                <td>6.058 m</td>
                <td>2.438 m</td>
                <td>2.233 m</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>2,750 Kgs</td>
                <td>24,000 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Platform-40-15.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Platform-40-15.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Platform-40-15.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Platform 40&#8242;
                    </div>
                </th>
                <th>Inside Length</th>
                <th>Inside Width</th>
                <th>Inside Height</th>
                <th className="td-heading" width="9%">Door Width</th>
                <th className="td-heading" width="10%">Door Height</th>
                <th className="td-heading" width="8%">Capacity</th>
                <th className="td-heading" width="10%">Tare Weight</th>
                <th className="td-heading" width="10%">Maxi Cargo</th>
            </tr>
            <tr>
                <td>40&#8217;0&#8243;</td>
                <td>8&#8217;0&#8243;</td>
                <td>6&#8217;5&#8243;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>12,783 Lbs</td>
                <td>86,397 Lbs</td>
            </tr>
            <tr>
                <td>12.18 m</td>
                <td>2.40 m</td>
                <td>1.95 m</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>&#8211;</td>
                <td>5,800 Kgs</td>
                <td>39,200 Kgs</td>
            </tr>
        </tbody>
    </Table>
    
    <Table className="table table-bordered d-none d-sm-block container-specification" responsive>
        <tbody>
            <tr>
                <th className="white-bg" rowSpan="3" width="21%">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Chassis-33-Tri-Axle-16.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Chassis-33-Tri-Axle-16.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/Chassis-33-Tri-Axle-16.jpg" alt="" />
                            </noscript>
                        </noscript> 
                        Chassis 33&#8242; Tri-Axle
                    </div>
                </th>
                <th className="td-heading" width="49%">Length</th>
                <th className="td-heading" width="15%">Maxi Cargo</th>
                <th className="td-heading" width="15%">Tare Cargo</th>
            </tr>
            <tr>
                <td>Overall Extended 41&#8217;3&#8243;&#8221;</td>
                <td>10,900 Lbs</td>
                <td>44,500 Lbs</td>
            </tr>
            <tr>
                <td>Maximum Length 12.6 m</td>
                <td>4,950 Kgs</td>
                <td>20,185 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/chassis-40-17.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/chassis-40-17.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/chassis-40-17.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Chassis 40&#8242; Gooseneck
                    </div>
                </th>
                <th className="td-heading" width="49%">Length</th>
                <th className="td-heading" width="15%">Maxi Cargo</th>
                <th className="td-heading" width="15%">Tare Cargo</th>
            </tr>
            <tr>
                <td>Overall Extended 41&#8217;3&#8243;&#8221;</td>
                <td>6,500 Lbs</td>
                <td>44,500 Lbs</td>
            </tr>
            <tr>
                <td>Maximum Length 12.6 m</td>
                <td>2,950 Kgs</td>
                <td>20,185 Kgs</td>
            </tr>
            <tr>
                <th className="white-bg" rowSpan="3">
                    <div>
                        <img className="scale-with-grid lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/chasis-23-18.jpg" alt="" />
                        <noscript>
                            <img className="scale-with-grid lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/chasis-23-18.jpg" alt="" />
                            <noscript>
                                <img className="scale-with-grid lazyload lazyload" src="/wp-content/uploads/2022/01/chasis-23-18.jpg" alt="" />
                            </noscript>
                        </noscript>
                        Chassis 23&#8217;5&#8243;
                    </div>
                </th>
                <th className="td-heading" width="49%">Length</th>
                <th className="td-heading" width="15%">Maxi Cargo</th>
                <th className="td-heading" width="15%">Tare Cargo</th>
            </tr>
            <tr>
                <td>Overall Extended 23&#8217;6&#8243;</td>
                <td>6,600 Lbs</td>
                <td>33,500 Lbs</td>
            </tr>
            <tr>
                <td>Overall Extended 27&#8217;2&#8243;</td>
                <td>6,600 Lbs</td>
                <td>39,500 Kgs</td>
            </tr>
        </tbody>
    </Table>
    </Col>
  )
}

export default ContainerSpecification
import { Bounce } from "react-awesome-reveal"
import ScrollToTop from '#helpers/ScrollToTop'

const NotFound = () => {
  return (
    <>
      <ScrollToTop />
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center py-5">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                  <Bounce delay={100}>
                    <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                    <h1 className="display-1">404</h1>
                    <h1 className="mb-4">Page Not Found</h1>
                    <p className="mb-4">
                      We’re sorry, the page you are searching for does not exist!
                      Check the URL and try again or simply go back to our home page.
                    </p>
                    <a className="btn btn-primary rounded-pill py-3 px-5" href="/">Go Back To Home</a>
                  </Bounce>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default NotFound
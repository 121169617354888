import React from 'react'
import { Col, Table } from 'react-bootstrap'
import './FreightPallet.scss'

const FreightPallet = () => {
  return (
    <Col className='freight-pallet'>
      <h2 className='heading heading-centered heading-underlined text-primary'>Air Freight Pallets</h2>
    <Table striped responsive>
        <thead className='bg-primary text-light'>
            <tr>
                <th className="td-heading">Type</th>
                <th className="td-heading">Code</th>
                <th className="td-heading">Base Dim Outside</th>
                <th className="td-heading">Height Outside</th>
                <th className="td-heading">Useable Volume</th>
                <th className="td-heading">Dimension Inside</th>
                <th className="td-heading">Acceptable for</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className="img-bg">
                    <div>
                        <img className="lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/LD3-container-1.jpg" alt="" />
                        <noscript>
                            <img className="lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/LD3-container-1.jpg" alt="" />
                            <noscript>
                                <img className="lazyload lazyload" src="/wp-content/uploads/2022/01/LD3-container-1.jpg" alt="" />
                            </noscript>
                        </noscript>
                    </div>
                    <div className="cont-name">LD3 Container</div>
                </td>
                <td>AKE / AVE</td>
                <td>1.5&#215;1.5m</td>
                <td>1.6m</td>
                <td>3m<sup>3</sup></td>
                <td>1.4&#215;1.4&#215;1.5m</td>
                <td>A300,A310,A330,A340,B747-200F,B747-400,B767-400,B767(DE),MD11F</td>
            </tr>
            <tr>
                <td className="img-bg">
                    <div>
                        <img className="lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Standard-pallet-2.jpg" alt="" />
                        <noscript>
                            <img className="lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/Standard-pallet-2.jpg" alt="" />
                            <noscript>
                                <img className="lazyload lazyload" src="/wp-content/uploads/2022/01/Standard-pallet-2.jpg" alt="" />
                            </noscript>
                        </noscript>
                    </div>
                    <div className="cont-name">Standard Pallet</div>
                </td>
                <td>PAG,PAJ</td>
                <td>3.1&#215;2.2m</td>
                <td></td>
                <td>3&#215;2.1m</td>
                <td></td>
                <td>A300,A31,A330,A340,B747-200F,B747-400,MD11F</td>
            </tr>
            <tr>
                <td className="img-bg">
                    <div>
                        <img className="lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/10ft-pallet-3.jpg" alt="" />
                        <noscript>
                            <img className="lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/10ft-pallet-3.jpg" alt="" />
                            <noscript>
                                <img className="lazyload lazyload" src="/wp-content/uploads/2022/01/10ft-pallet-3.jpg" alt="" />
                            </noscript>
                        </noscript>
                    </div>
                    <div className="cont-name">10Ft Pallet</div>
                </td>
                <td>PQP,PMC</td>
                <td>3.1&#215;2.4m</td>
                <td></td>
                <td>3&#215;2.3m</td>
                <td></td>
                <td>A300,A310,A330,A340,B747-200F,B747-400,MD11F</td>
            </tr>
            <tr>
                <td className="img-bg">
                    <div>
                        <img className="lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/20ft-pallet-4.jpg" alt="" />
                        <noscript>
                            <img className="lazyload lazyload" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/wp-content/uploads/2022/01/20ft-pallet-4.jpg" alt="" />
                            <noscript>
                                <img className="lazyload lazyload" src="/wp-content/uploads/2022/01/20ft-pallet-4.jpg" alt="" />
                            </noscript>
                        </noscript>
                    </div>
                    <div className="cont-name">20Ft Pallet</div>
                </td>
                <td>PGE</td>
                <td>6&#215;2.4m</td>
                <td></td>
                <td>5.9&#215;2.3m</td>
                <td></td>
                <td>B747-200F,MD11F</td>
            </tr>
        </tbody>
    </Table>
    </Col>
  )
}

export default FreightPallet
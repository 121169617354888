import Agency from '#components/agency/Agency'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import './Services.scss'
import { useServices } from '#hooks'
import { useLocation, useNavigationType } from 'react-router-dom'
import useURLQuery from '#helpers/UrlQuery'
import { setInnerHTML } from '#helpers'
import { useEffect, useRef } from 'react'

const Services = () => {
  const services = useServices()
  const location = useLocation()
  const navigationType = useNavigationType()
  const { searchQueryString } = useURLQuery()
  const activeAccordionKey = searchQueryString('title', location.search)
  const accordionRef = useRef(null) // To reference the accordion with same title as accordionKey

  useEffect(() => {
    if (accordionRef.current) {
      /* 
      If title is defined in url query string, then scroll accordion with that title into view 
      accordionRef will only have a non null value if that condition is satisfied
      */
      window.scrollTo({
        left: 0, 
        top: accordionRef.current.offsetTop - '100'
      })
    } else if (navigationType === 'PUSH' || navigationType === 'REPLACE') {
      // If no accordionRef, (meaning no title in url query string), scroll to top
      window.scrollTo(0,0)
    }
  }, [activeAccordionKey, navigationType])

  return (
    <>
      <Container className='image-div' fluid>
        <Row className='justify-content-center' style={{backgroundColor: 'var(--bs-gray-200)'}}>
          <Col>
            <div className='main-img' style={{backgroundImage: 'url(/assets/img/Air-slider.webp)'}}></div>
          </Col>
        </Row>
      </Container>
      <Container className='services-div'>
        <Row className='justify-content-center g-4'>
          <Col xs={12}>
            <h2 className='heading text-primary main-heading'>What we offer</h2>
          </Col>
          <Col>
            <Accordion defaultActiveKey={activeAccordionKey}>
              {
                services.map((service, index) => 
                  <Accordion.Item ref={service.title === activeAccordionKey ? accordionRef : null} className='service-accordion' key={service.id} eventKey={service.title}>
                    <Accordion.Header>{service.title}</Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={setInnerHTML(service.description)}></div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              }
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Agency />
    </>
  )
}

export default Services
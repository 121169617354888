import './App.scss';
import '#scss';
import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Header from '#components/header/Header';
import Footer from '#components/footer/Footer';
import About from './pages/about/About';
import NotFound from './pages/notFound/NotFound';
import Services from './pages/services/Services';
import Resources from './pages/resources/Resources';
import SideBar from './components/sideBar/SideBar';

function App() {
  return (
    <div className='App'>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path='contact' element={<Contact />} />
        <Route path='about' element={<About />} />
        <Route path='services' element={<Services />} />
        <Route path='resources/:resource' element={<Resources />} />
        <Route path='404' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <SideBar />
      <Footer />
    </div>
  );
}

export default App;

const navMapping = {
    home: {
        key: 0,
        pathName: '/'
    },
    about: {
        key: 1,
        pathName: '/about'
    },
    contact: {
        key: 2,
        pathName: '/contact'
    },
    services: {
        key: 3,
        pathName: '/services'
    },
    freightPallets: {
        key: 4,
        pathName: '/resources/freight-pallets'
    },
    incoTerms: {
        key: 5,
        pathName: '/resources/inco-terms'
    },
    containerSpecification: {
        key: 6,
        pathName: '/resources/container-specification'
    }
}

export default navMapping
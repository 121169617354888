import emailjs from '@emailjs/browser'

export const useServices = () => {
  const services = [
    {
      title: 'Shipping, Air Cargo and Freight Services',
      imgURL: '/assets/img/Air-slider.webp',
      id: 'service-0',
      description: `<p>As a Professional International Logistics Service Provider, <strong><em>KOLAT-OAK LOGISTICS 
      AGENCY</em></strong> utilizes the world’s leading airlines to provide international freight services in hundreds of trade lanes. 
      By focusing on a core group of key commercial airline and charter operators.</p><p><strong><em>KOLAT-OAK LOGISTICS AGENCY</em></strong>
      leverages its volumes to obtain effective space allocations, regular flight schedules, and competitive pricing.
      </p><p>Our long-term cooperative airlines include: Airfrance (AF), Emirates Airlines (EK), Turkish Airlines (TK), 
      Lufthansa (LH), Kenya Airways(KQ), Qatar Airlines (QR), Allied  Air (AJK), Delta Airlines (DL), Ethiopia 
      Airlines (ET),United Airlines (UA), Saudi Arabia Airlines (SV), South Africa Airlines (SA), KLM Cargo(KL) 
      and many more. We always provide a variety of shipping options, giving our clients the flexibility to decide on 
      what option best suits their needs.</p><p>Also provide negotiable and competitive rates for cargo including 
      direct flight, transit & air transport and charter flight.</p><p>In addition, we provide world wide route and 
      resources integration to guarantee the value of services to meet the needs of different categories of customers.</p>
      <p>Our Global Air Freight Services also include:</p><ol><li>Worldwide carrier Selection for your cargo</li>
      <li>Air Carrier Booking</li><li>Daily Uplifts Worldwide (Export and Import)</li><li>Direct Services from origin to all International Airports</li>
      <li>Airway Bill(AWB) Freight Documentation</li><li>Customs Brokerage</li><li>Door to Door Service</li>
      <li>Freight & Transport Insurance</li><li>Special Packing Service</li></ol>`
    },
    {
      title: 'Inboud and Outbound Shipment',
      imgURL: '/assets/img/import-and-export.jpg',
      id: 'service-1',
      description: `We specializes on inbound and Outbound Consignments from different countries 
      worldwide locally and internationally.`
    },
    {
      title: 'Custom Declaration and Inspection',
      imgURL: '/assets/img/custom-inspection.jpg',
      id: 'service-2',
      description: `We provide customs formalities, processes and procedures for all our export and import 
      consignments to our esteemed clients. We also assist in providing declaration  and inspection of consignments 
      either at point of origin or at point of destination.In addition,  we cater for Customs declaration service 
      for sea ( FCL, LCL) and air shipments`
    },
    {
      title: 'Cargo Consolidation and Handling',
      imgURL: '/assets/img/consolidation.jpg',
      id: 'service-3',
      description: `At <strong><em>KOLAT-OAK LOGISTICS AGENCY</em></strong>,  We always believe in delivering 
      logistical services big or small, on-time, across the globe. As such, we as a consolidator provide direct 
      consolidation services from most part of the world to and from either locally or internationally and handle 
      such shipments with professional conduct.`
    },
    {
      title: 'Sea Freight (LCL, FCL)',
      imgURL: '/assets/img/sea-freight.jpg',
      id: 'service-4',
      description: `We provide the best service possible, we have an ocean freight department specializing 
      in FCL/LCL shipments, imports and exports in the handling of different types of break bulk and over-sized 
      cargoes. We also provide destination clearance and door to door service  at the most competitive rates. 
      With a wide network of agents and associates worldwide, we guarantee the highest level of service and 
      commitment. A dedicated team of professionals are highly geared up to provide  you  the most comprehensive 
      and customized services at reasonable prices.`
    },
    {
      title: 'Custom Clearance, Processing and Documentation and Door to Door Delivery',
      imgURL: '/assets/img/Customs.webp',
      id: 'service-6',
      description: `At <strong><em>KOLAT-OAK LOGISTICS AGENCY</em></strong>, we provide cost effective custom clearing services. 
      We handle the clearance of various consignments arriving by air, land and sea. We ensure speedy clearance 
      of your consignment and delivery to your doorstep. With our extensive network around the world, we provide 
      necessary documentation & formalities and ensure punctual delivery at your door step.`
    },
    {
      title: 'Warehouse and Distribution',
      imgURL: '/assets/img/warehouse.jpg',
      id: 'service-7',
      description: `We operates warehousing and distribution facilities in order to provide our customers a 
      comprehensive range of logistical services. Our dedicated warehousing facilities are strategically located. 
      It is convenient to use, easily accessible and able to provide quality storage and handling with 
      state-of-the-art security control. In addition, our distribution service offers our customers just-in-time 
      local delivery and collection.`
    },
    {
      title: 'Clearing and Freight Forwarding Agency',
      imgURL: '/assets/img/clearing.jpg',
      id: 'service-8',
      description: `As an International Logistics Provider, we also provide all documentation, cargo handling, 
      examination of shipments, cargo carting, ocean and airfreight forwarding.`
    },
    {
      title: 'Pickup and Loading at Origin',
      imgURL: '/assets/img/pickup.jpg',
      id: 'service-9',
      description: `As a Logistics Expert with partners all over the world, we have oversea forwarders that handle 
      our client's consignment with Cargo Handling Procedures for Import & Export during pick up and unloading as 
      well at the point of Origin.`
    },
    {
      title: 'Cost Saving Solution (Tax/Duties and Freight)',
      imgURL: '/assets/img/tax.jpg',
      id: 'service-10',
      description: `We provide latest updates of procedures, rules, and regulations associated to trade and customs 
      restrictions. Our clearance department is well vast in giving constant assistance to the clients in regard to 
      Legal Valuation and Assessment of the Goods, Custom Duty and Tax Assessment and ensure a competitive and 
      affordable Freight to our esteemed clients.`
    },
    {
      title: 'Haulage and General Contract',
      imgURL: '/assets/img/haulage.jpg',
      id: 'service-11',
      description: `<strong><em>KOLAT-OAK LOGISTICS AGENCY</em></strong> are always engage in domestic trucking services from/to all locations 
      with its range of fleet types and delivery capabilities can handle any types of goods, whether it is general 
      cargo, temperature-sensitive perishables, tractor units, spare parts, construction & medical equipment, power 
      plant and special equipment, out of gauge equipment, vehicles , medicines and dangerous shipments.`
    },
    {
      title: 'Courier Support Services',
      imgURL: '/assets/img/courier.jpg',
      id: 'service-12',
      description: `For urgent consignments, we extend our services to courier companies such as DHL, FEDEX, UPS, 
      etc for timely delivery of such packages`
    }
  ]

  return services
}

export const useAgency = () => {
  const agencies = [
    {
      name: 'FIATA',
      imgURL: '/assets/img/fiata.png',
      style: {
        flexGrow: 1
      }
    },
    {
      name: 'IATA',
      imgURL: '/assets/img/iata.png',
      style: {
        flexGrow: 1
      }
    },
    {
      name: 'WCA',
      imgURL: '/assets/img/wca.png',
      style: {
        flexGrow: 1
      }
    },
    {
      name: 'CargoYellow',
      imgURL: '/assets/img/fiata.png',
      style: {
        flexGrow: 1
      }
    },
    {
      name: 'JC Trans',
      imgURL: '/assets/img/jctrans.png',
      style: {
        flexGrow: 2
      }
    },
    {
      name: 'FreightNet',
      imgURL: '/assets/img/freightnet.png',
      style: {
        flexGrow: 2
      }
    }
  ]

  return agencies;
}

export const useSendMail = () => {

  const sendMail = async (userName, userEmail, userPhone, message) => {
    /**
     * Sends mail to admin's email
     * 
     * @param userName (string) - name of the user trying to reach admin
     * @param userEmail (string) - email of the user trying to reach admin
     * @param userPhone (number) - phone number of user trying to reach admin
     * @param message (string)
     * 
     * return an object with attribute ok set to true if message sent succesfully, else false
     */
    const response = {
      ok: false
    }
  
    if (userEmail && message) {
      const SERVICEID = 'service_ey30r0w'
      const PUBLICKEY = 'vkLNSDuQcejwFCjEu'
      const TEMPLATEID = 'template_r3itrj8'
      const mailParams = {
        from_name: userName,
        message,
        phone: userPhone,
        user_email: userEmail
      }

      return emailjs.send(SERVICEID, TEMPLATEID, mailParams, PUBLICKEY)
      .then(() => {
        response.ok = true
        return response
      }, () => {
        return response
      })
    }
  }

  return {send: sendMail}
}
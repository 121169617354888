import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import './Header.scss'
import { AttentionSeeker } from 'react-awesome-reveal'
import navMapping from '#config/routes'

const Header = () => {
  const location = useLocation()
  const activeNavKey = (() => {
    let x, navItem;
    for (x in navMapping) {
      navItem = navMapping[x]
      if (navItem.pathName.includes(location.pathname)) {
        return navItem.key
      }
    }
  })()

  return (
    <>
      <Navbar bg='white' variant='light' expand='md' className="shadow border-top border-5 border-primary sticky-top p-0" collapseOnSelect={true}>
        <Container className='pr-3' fluid>
          <Navbar.Brand className='px-0 px-lg-2'>
            <Image className='logo' src='/assets/logo.jpg'/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ms-auto' activeKey={activeNavKey}>
              <Nav.Item>
                <Nav.Link to={navMapping.home.pathName} eventKey={navMapping.home.key} as={Link}>Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to={navMapping.about.pathName} eventKey={navMapping.about.key} as={Link}>About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to={navMapping.services.pathName} eventKey={navMapping.services.key} as={Link}>Services</Nav.Link>
              </Nav.Item>
              <NavDropdown title='Resources' id='resources-dropdown'>
                <NavDropdown.Item as='span'>
                  <Nav.Link to={navMapping.incoTerms.pathName} eventKey={navMapping.incoTerms.key} as={Link}>Inco Terms</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item as='span'>
                  <Nav.Link to={navMapping.freightPallets.pathName} eventKey={navMapping.freightPallets.key} as={Link}> Air Freight Pallets</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item as='span'>
                  <Nav.Link to={navMapping.containerSpecification.pathName} eventKey={navMapping.containerSpecification.key} as={Link}>Container Specification</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as='span'>
                  <Nav.Link href="http://erp.fresaxpress.com:19787/apex/f?p=201:969:462125845540::NO:969::" target="_blank" rel="noopener noreferrer" className="dropdown-item">Measurement Convertor</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item as='span'>
                  <Nav.Link href="http://erp.fresaxpress.com:19787/apex/f?p=107:acronyms" target="_blank" rel="noopener noreferrer" className="dropdown-item">Acronyms</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item as='span'>
                  <Nav.Link href="http://erp.fresaxpress.com:19787/apex/f?p=107:733:0::NO::" target="_blank" rel="noopener noreferrer" className="dropdown-item">World Sea Ports</Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item as='span'>
                  <Nav.Link href="http://erp.fresaxpress.com:19787/apex/f?p=107:734:0::NO::" target="_blank" rel="noopener noreferrer" className="dropdown-item">World Air Ports</Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Item>
                <Nav.Link to={navMapping.contact.pathName} eventKey={navMapping.contact.key} as={Link}>Contact</Nav.Link>
              </Nav.Item>
            </Nav>
            <AttentionSeeker>
            <p className="text-primary nav-phone-text">
              <a href='tel:+2347037476444'>
                <Image src='/assets/svg/headphone-blue.svg' className="me-1"/>+234 703 7476 444
              </a>
            </p>
          </AttentionSeeker>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import './Mission.scss'
import { Fade, Slide } from 'react-awesome-reveal'

const Mission = () => {
  return (
      <Container className='mission-row' fluid>
        <Row>
            <Col xs={12} md={6} className='d-flex justify-content-center'>
              <Fade triggerOnce fraction={0.2}>
                <Card id='mission' className='mission-card'>
                  <Card.Body>
                    <div>
                      <h2 className='heading'>Our Mission</h2>
                      <p>
                        To be your leading global partner in providing cargo transportation, logistic support and 
                        international logistics service provider through innovative, value-added and state-of-the-art 
                        practices for mutual benefit and profitability.
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col xs={12} md={6} className='d-flex justify-content-center'>
              <Slide triggerOnce direction='right' fraction={0.2}>
                <Card id='vision' className='vision-card'>
                  <Card.Body>
                    <div>
                      <h2 className='heading'>Our Vision</h2>
                      <p>
                        To be the world's best and most reliable Logistics partner, by means of providing 
                        excellence in service quality and delivering value. We provide cost beneficial solutions 
                        and services tailored to meet the specific and ever-changing requirements of international trade.
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Slide>
            </Col>
        </Row>
      </Container>
  )
}

export default Mission
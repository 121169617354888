const searchQueryString = (queryTitle, urlQueryString) => {
  /**
   * Returns the value of the parameter "queryTitle" from the query string (url)
   * 
   * @param queryTitle (string) - the key we want to get from the query string
   * @param urlQueryString (string) - the query string to be searched
   * Return: the value of title in the query string if found, else undefined
   */
  let value // The variable where the value of queryTitle will be stored
  const titleKey = `${queryTitle}=` // Added '=' here to account for it in length calculation
  const titleCharPosition = urlQueryString.indexOf(titleKey)
  if (titleCharPosition === -1) {
    return undefined
  }
  /* Get index of next '&' character starting from where titleKey was found */
  const positionOfNextDelimiter = urlQueryString.indexOf('&', titleCharPosition)
  if (positionOfNextDelimiter !== -1) { 
    /* Get characters between titleKey and the next '&' character */
    value = urlQueryString.slice(titleCharPosition + titleKey.length, positionOfNextDelimiter + 1)
  } else {
    /* 
      If there are no '&' character after it, get all characters to the end of query string.
      Meaning it's the only title/value pair in the query string or the last 
    */
    value = urlQueryString.slice(titleCharPosition + titleKey.length)
  }
  value = value.replaceAll('%20', ' ') // Simply to normalize the string
  return value
}

const useURLQuery = () => {
  return {searchQueryString}
}

export default useURLQuery